// dashboardSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import data from '../../data/data';
import { DL_URI } from '../../utils/Constants';
import axios from 'axios';

export const fetchUtilization = createAsyncThunk(
  'insights/fetchUtilization',
  async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const response = await axios.get(
      `${DL_URI}api/v1/insights/coach-utilization?startDate=2023-06-01&endDate=${formattedDate}`,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    console.log(response);
    return response.data;
  }
);


const initialState = {
  weeks: data,
  loading: false,
  error: null,
};

const utilizationSlice = createSlice({
  name: 'utilization',
  initialState: { entities: [], loading: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUtilization.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchUtilization.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.entities = action.payload;
      })
      .addCase(fetchUtilization.rejected, (state, action) => {
        state.loading = 'idle';
        console.error("Fetch utilization failed", action.error);
      });
  },
});

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
} = utilizationSlice.actions;

export default utilizationSlice.reducer;
