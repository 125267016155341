export const firebaseConfig = {
    apiKey: "AIzaSyCAg-SsSkw-Ao8Ui2CwSlvd97EByDqmAEw",
    authDomain: "present-3db54.firebaseapp.com",
    projectId: "present-3db54",
    storageBucket: "present-3db54.appspot.com",
    messagingSenderId: "666111156304",
    appId: "1:666111156304:web:5b2f957463b729a0937155",
    measurementId: "G-Q69H8JTH2L"
};

export const countryCodes = [
    {code: '+1', name: 'USA'},
    {code: '+91', name: 'India'},
    {code: '+44', name: 'UK'},
    {code: '+61', name: 'Australia'},
];

// export const DL_URI = 'http://local.getpresent.fit:8080/'
export const DL_URI = 'https://prsnt.fit/'