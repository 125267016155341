import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCustomers, fetchBatchCustomers, toggleCustomerStatus } from '../redux/slice/customerSlice';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardHeader, 
  CardContent, Button, CircularProgress, Alert, Switch 
} from '@mui/material';

function CustomersTable() {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.entities);
  const batchCustomers = useSelector((state) => state.customers.batchCustomers);

  const customersLoading = useSelector((state) => state.customers.loading);
  const customersError = useSelector((state) => state.customers.error);

  useEffect(() => {
    if (customersLoading === 'idle') {
      dispatch(fetchCustomers());
      dispatch(fetchBatchCustomers());
    }
  }, [dispatch]);

  if (customersLoading === 'loading') {
    return <CircularProgress />;
  }

  if (customersError) {
    return <Alert severity="error">Failed to load customers!</Alert>;
  }

  const handleToggleStatus = (customerId, batchId, isDeleted) => {
    dispatch(toggleCustomerStatus({customerId, batchId, isDeleted: !isDeleted}));
  };

  const renderToggle = (customer) => {

    let toggle = null;

    let batchCustomer = batchCustomers.find(bc => bc.customer.id == customer.id);

    if(!!batchCustomer) {
      toggle = <Switch 
                  checked={!batchCustomer.isDeleted} 
                  onChange={() => handleToggleStatus(customer.id, customer.batches[0].id, batchCustomer.isDeleted)} 
                  color="primary"
                />;
    }

    return(toggle);
  };

  return (
    <Card variant="outlined"> 
      <CardHeader title="Customers" />
      <CardContent>
        <Link to="/customers/add">
          <Button variant="contained" sx={{marginBottom: 2}}>Add Customer</Button>
        </Link>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="left">First Name</TableCell>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">Mobile Number</TableCell>
                <TableCell align="left">Batch</TableCell>
                <TableCell align="left">Active</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell component="th" scope="row">
                    {customer.id}
                  </TableCell>
                  <TableCell align="left">{customer.firstName}</TableCell>
                  <TableCell align="left">{customer.lastName}</TableCell>
                  <TableCell align="left">+{customer.mobileNumber}</TableCell>
                  <TableCell align="left">{customer.batches[0].name}</TableCell>
                  <TableCell align="left">
                    { renderToggle(customer) }
                  </TableCell>
                  <TableCell align="left"> 
                    <Link to={`/customers/edit/${customer.id}`}>
                      <Button variant="outlined" color="primary" size="small">
                        Edit
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default CustomersTable;