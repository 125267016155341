import { styled } from "@mui/system";

const coreStyles = styled(theme => ({
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap', // This makes the button group wrap

    // Targeting the buttons inside the button group
    '& > *': {
      margin: theme.spacing(1), // Optional: add some margin between the buttons when they wrap
    },
  },
}));

export default coreStyles;