export const utils = {
    deepcopy: (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },

    stringifyDate: (date) => {
        const year = date.getFullYear(); // 2023
        let month = date.getMonth() + 1; // 7, getMonth() starts from 0
        let day = date.getDate(); // 13

        // if month and day are less than 10, add a leading 0
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        const dateString = `${year}-${month}-${day}`;

        return dateString;
    },

    extractPhoneNumberComponents: (str) => {
        if(!str || str.length < 10) return { countryCode: null, phoneNumber: null };

        let countryCode = "+" + Number(str.slice(0, 2));
        let phoneNumber = str.slice(2);
    
        return { countryCode, phoneNumber };
    }
};

export const validationUtils = {
    validateEmail: (email) => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(email);
    },
      
    validatePhoneNumber: (phoneNumber) => {
        const phoneRegex = /^\d{10}$/; // Adjust according to the structure you need
        return phoneRegex.test(phoneNumber);
    }
};