import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { Container, AppBar, Toolbar, Typography, Button } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { setCoachData } from './redux/actions';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

// import data from './data/data';

import SignIn from "./components/SignIn";
import CustomersTable from "./components/CustomersTable";
import CustomerSave from './components/CustomerSave'
import CustomerEdit from './components/CustomerEdit'
import InsightsDashboard from './components/InsightsDashboard';

import { firebaseConfig } from "./utils/Constants";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);

  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(firebaseApp);

  // useEffect(() => {
  //   dispatch(setCoachData(data));
  // }, [dispatch]);

  const renderUI = () => {
    let obj = null;

    if(!isAuthenticated) {
      obj = <SignIn />;
    } else {
      obj = <Router>
      <AppBar position="static" style={{ backgroundColor: '#31426E' }}>
        <Toolbar>
          <Typography variant="h6">
            Present Studio
          </Typography>
          <Button color="inherit" component={Link} to="/customers">Customers</Button>
          <Button color="inherit" component={Link} to="/insights">Insights</Button>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/"
          element={<Navigate to="/insights" replace={true} />}
        />
        <Route path="/customers" element={<CustomersTable />} />
        <Route path="/insights" element={ <InsightsDashboard />} />
        <Route path="/customers/add" element={<CustomerSave />} />
        <Route path="/customers/edit/:id" element={<CustomerEdit />} />
      </Routes>
    </Router>;
    }

    return obj;
  }

  return (
    <Container>
      { renderUI() }
    </Container>
  );
}

export default App;
