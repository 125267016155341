// store.js
import { configureStore } from '@reduxjs/toolkit';
import utilizationReducer from './slice/utilizationSlice';
import userReducer from './slice/userSlice';
import customerReducer from './slice/customerSlice';
import batchReducer from './slice/batchSlice';

export default configureStore({
  reducer: {
    utilization: utilizationReducer,
    user: userReducer,
    customers: customerReducer,
    batches: batchReducer,
  },
});
