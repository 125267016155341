import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomerSave from './CustomerSave';
import { fetchSingleCustomer } from '../redux/slice/customerSlice';

function CustomerEdit() {
  const dispatch = useDispatch();
  const { id } = useParams(); // Assuming you have a route like /customers/edit/:id

  // Assuming you have a selector that can get a customer by ID
  const customer = useSelector((state) => state.customers.entities.find(c => c.id === Number(id)));

  useEffect(() => {
    if (!customer) {
      dispatch(fetchSingleCustomer({ id }));
    }
  }, [id, dispatch, customer]);

  // Wait until the customer is loaded
  if (!customer) {
    return <div>Loading...</div>;
  }

  return <CustomerSave editingCustomer={customer} />;
}

export default CustomerEdit;
