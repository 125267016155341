import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, ButtonGroup, Card, CardHeader, CardContent } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsYAxis } from '@mui/x-charts';

import { fetchUtilization } from '../redux/slice/utilizationSlice';
import { fetchBatches } from '../redux/slice/batchSlice';
import { utils } from '../utils/utils';
import coreStyles from '../styles/coreStyles'; 

function InsightsDashboard() {
  const dispatch = useDispatch();

  const utilization = useSelector((state) => state.utilization.entities);
  const batches = useSelector((state) => state.batches.entities);
  const customersLoading = useSelector((state) => state.utilization.loading);

  const [selectedCoach, setValue] = useState('');

  const theme = useTheme();
  const classes = coreStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chartWidth = isMobile ? "300" : "900";

  // Effect for fetching data
  useEffect(() => {
    if (customersLoading === 'idle' && (!batches.length && !utilization.length)) {
        dispatch(fetchUtilization());
        dispatch(fetchBatches());
    }
  }, [customersLoading, dispatch]);

  // Effect for setting the selectedCoach
  useEffect(() => {
    if(!selectedCoach && batches && batches.length > 0) {
        const batch = batches[0];
        const coach = batch.coaches[0];

        if(coach) {
            selectCoach(coach);
        }
    }
  }, [selectedCoach, batches]);

  const selectCoach = (coach) => {
    setValue(coach);
  }

  const overallUtilization = (utilization) => {
    const output = {};

    for(const row of utilization) {
      const key = `${row.startDate}--${row.endDate}`;

      if(!output[key]) {
        output[key] = {
          activeMembers: 0,
          memberCapacity: 0,
          slotsPerWeek: 0,
          slotsFilled: 0,
          startDate: row.startDate,
          endDate: row.endDate
        }
      }

      output[key].activeMembers += row.activeMembers;
      output[key].memberCapacity += row.memberCapacity;
      output[key].slotsPerWeek += row.slotsPerWeek;
      output[key].slotsFilled += row.slotsFilled;
    }

    let data = [];
    let chartData = [];
    let chartXaxis = [];
    let chartYaxis = [];
    
    for(let [key, stats] of Object.entries(output)) {
      const fillRate = Math.round((stats.activeMembers / stats.memberCapacity) * 100);
      const slotUtilization = Math.round((stats.slotsFilled / stats.slotsPerWeek) * 100)
      const overallUtilization = Math.round((fillRate / 100) * (slotUtilization));

      data.push(
        <TableRow>
        <TableCell align="left">{stats.startDate}</TableCell>
        <TableCell align="left">{stats.endDate}</TableCell>
        <TableCell align="left">{fillRate}%</TableCell>
        <TableCell align="left">{slotUtilization}%</TableCell>
        <TableCell align="left">{overallUtilization}%</TableCell>
      </TableRow>
      );

      let chartObj = {
        date: new Date(stats.endDate),
        overallUtilization: overallUtilization
      }

      chartData.push(chartObj);
    }

    let chart = null;
    if(chartData.length > 1) {
      chartData.sort((a,b) => a.date - b.date);

      chart = <LineChart
                width={chartWidth}
                height={300}
                series={
                  [{data: chartData.map(c => c.overallUtilization), label: "utilization"}]
                }
                xAxis={
                  [{ 
                    scaleType: 'point', 
                    data: chartData.map(c => utils.stringifyDate(c.date))
                  }]
                }
              />;
    }

    let table = null;

    if(data.length) {
      chartData.sort((a,b) => new Date(a.endDate) - new Date(b.endDate));

      table = <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Start Date</TableCell>
                      <TableCell align="left">End Date</TableCell>
                      <TableCell align="left">Fill Rate</TableCell>
                      <TableCell align="left">Slot Utilization</TableCell>
                      <TableCell align="left">Overall Utilization</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data}
                  </TableBody>
                </Table>
              </TableContainer>
    }

    return (
      <div>
        {chart}
        {table}
      </div>
      
    );
  }


  const utilizationMetrics = (utilization) => {

    let output = [];

    for(let utilizationRow of utilization) {
      let row = utils.deepcopy(utilizationRow);

      const batch = batches.find(batch => batch.id == row.batchId);
      if(batch && selectedCoach && selectedCoach.id == row.coachId) {
        row.batchName = batch.name;
        row.coachName = batch.coaches[0].firstName;

        row["slotUtilization"] = Math.round((row.slotsFilled / row.slotsPerWeek) * 100);

        output.push(generateRowData(row));
      }
    }
    return output;
  }

  const generateRowData = (row) => {
    return (
      <TableRow>
        {/* <TableCell component="th" scope="row">
          {row.coachName}
        </TableCell> */}
        <TableCell align="left">{row.batchName}</TableCell>
        <TableCell align="left">{row.startDate}</TableCell>
        <TableCell align="left">{row.endDate}</TableCell>
        <TableCell align="left">{row.activeMembers}</TableCell>
        <TableCell align="left">{row.memberCapacity}</TableCell>
        <TableCell align="left">{Math.round(row.fillRate * 100)}%</TableCell>
        <TableCell align="left">{row.slotsPerWeek}</TableCell>
        <TableCell align="left">{row.slotsFilled}</TableCell>
        <TableCell align="left">{row.slotUtilization}%</TableCell>
        <TableCell align="left">{Math.round(row.overallUtilization*100)}%</TableCell>
      </TableRow>
    );
  }

  const generateCoachSelectors = (batches) => {
    let coaches = {};
    let menuItems = [];
    
    for(let batch of batches) {
      const coach = batch.coaches[0];

      if(!coaches[coach.id]) {
        coaches[coach.id] = coach;

        menuItems.push(
          <MenuItem key={coach.id} value={coach.id}>
            {coach.firstName}
          </MenuItem>
        );
      }
    }

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Select Coach</InputLabel>
        <Select
          label="Select Coach"
          value={selectedCoach ? selectedCoach.id : ''}
          onChange={(event) => {
            const selected = coaches[event.target.value];
            selectCoach(selected);
          }}
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  };

  const generateCoachData = () => {

    let table = null;

    if(!!selectedCoach) {
      table = <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Coach Name</TableCell> */}
                      <TableCell align="left">Batch Name</TableCell>
                      <TableCell align="left">Start Date</TableCell>
                      <TableCell align="left">End Date</TableCell>
                      <TableCell align="left">Active Members</TableCell>
                      <TableCell align="left">Member Capacity</TableCell>
                      <TableCell align="left">Fill Rate</TableCell>
                      <TableCell align="left">Slots Per Week</TableCell>
                      <TableCell align="left">Slots Filled</TableCell>
                      <TableCell align="left">Slot Utilization</TableCell>
                      <TableCell align="left">Overall Utilization</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {utilizationMetrics(utilization)}
                  </TableBody>
                </Table>
              </TableContainer>;
    }

    return (
      <div>
        <div>
          {generateCoachSelectors(batches)}  
        </div>
        {table}
      </div>
    );

  }

  return (
    <Paper elevation={3}>
      <Card variant="outlined"> 
        <CardHeader
          title="Overall Utilization"
        />
        <CardContent>
          {overallUtilization(utilization)}
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Coach Utilization"
          subheader="Select coach to view their utilization details"
        />
        <CardContent>
          {generateCoachData()}
        </CardContent>
      </Card>
      
    </Paper>
    
  );
}

export default InsightsDashboard;
