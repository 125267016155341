import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DL_URI } from '../../utils/Constants';
import axios from 'axios';

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async () => {
    const response = await axios.get(
      `${DL_URI}api/v1/customer/all`,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    console.log(response);
    return response.data;
  }
);

export const fetchSingleCustomer = createAsyncThunk(
  'customers/fetchSingleCustomer',
  async ({ id }) => {
    const response = await axios.get(
      `${DL_URI}api/v1/customer/${id}`,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    console.log(response);
    return response.data;
  }
);

export const fetchBatchCustomers = createAsyncThunk(
  'customers/fetchBatchCustomers',
  async () => {
    const response = await axios.get(
      `${DL_URI}api/v1/customer/batch-map`,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    console.log(response);
    return response.data;
  }
);

export const saveCustomer = createAsyncThunk(
  'customers/saveCustomer',
  async (customerData) => {
    const response = await axios.post(
      `${DL_URI}api/v1/customer`,
      customerData,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    console.log(response);
    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async (customerData) => {
    const response = await axios.put(
      `${DL_URI}api/v1/customer/${customerData.id}`,
      customerData,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    console.log(response);
    return response.data;
  }
);

export const toggleCustomerStatus = createAsyncThunk(
  'customers/toggleStatus',
  async ({customerId, batchId, isDeleted}) => {
    const body = {
      'isDeleted': isDeleted
    };
    const response = await axios.put(`${DL_URI}api/v1/customer/${customerId}/batch/${batchId}`, 
      body,
      {headers: {
        'Content-Type': 'application/json'
      }}
    );
    console.log(response);
    return response.data;
  }
);


const customersSlice = createSlice({
  name: 'customers',
  initialState: { entities: [], batchCustomers: [], loading: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = 'loading';
        state.error = null;  // clear error state when starting a new request
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.entities = action.payload;
        state.error = null;  // clear error state when request is successful
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error.message;  // update error state when request fails
        console.error("Fetch customers failed", action.error);
      })
      .addCase(fetchSingleCustomer.pending, (state) => {
        state.loading = 'loading';
        state.error = null;  // clear error state when starting a new request
      })
      .addCase(fetchSingleCustomer.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.entities = [action.payload];
        state.error = null;  // clear error state when request is successful
      })
      .addCase(fetchSingleCustomer.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error.message;  // update error state when request fails
        console.error("Fetch customers failed", action.error);
      })
      .addCase(fetchBatchCustomers.pending, (state) => {
        state.loading = 'loading';
        state.error = null;  // clear error state when starting a new request
      })
      .addCase(fetchBatchCustomers.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.batchCustomers = action.payload;
        state.error = null;  // clear error state when request is successful
      })
      .addCase(fetchBatchCustomers.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error.message;  // update error state when request fails
        console.error("Fetch batch customers failed", action.error);
      })
      .addCase(saveCustomer.pending, (state) => {
        state.loading = 'loading';
        state.error = null;  // clear error state when starting a new request
      })
      .addCase(saveCustomer.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.entities.push(action.payload);
        state.error = null;  // clear error state when request is successful
      })
      .addCase(saveCustomer.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error.message;  // update error state when request fails
        console.error("Save customer failed", action.error);
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = 'loading';
        state.error = null;  // clear error state when starting a new request
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = 'idle';
        
        let updatedEntities = state.entities.filter(c => c.id !== action.payload.id);
        updatedEntities.push(action.payload);
        const updatedEntitiesOrdered = updatedEntities.slice().sort((a, b) => a.id - b.id);

        state.entities = updatedEntitiesOrdered;
        state.error = null;  // clear error state when request is successful
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error.message;  // update error state when request fails
        console.error("Update customer failed", action.error);
      })
      .addCase(toggleCustomerStatus.fulfilled, (state, action) => {
        const batchCustomer = state.batchCustomers.find(bc => bc.customer.id === action.meta.arg.customerId 
          && bc.batch.id == action.meta.arg.batchId); // action.meta.arg contains the customerId passed to the thunk
        if (batchCustomer) {
          batchCustomer.isDeleted = !batchCustomer.isDeleted; // optimistically toggle the status
        }
      });
  },
});

export default customersSlice.reducer;