import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DL_URI } from '../../utils/Constants';
import axios from 'axios';

export const fetchBatches = createAsyncThunk(
  'coach/fetchBatches',
  async () => {
    const response = await axios.get(
      `${DL_URI}batch/all`,
      { headers: {
          'Content-Type': 'application/json'        
      } }
    );
    return response.data;
  }
);

const batchSlice = createSlice({
  name: 'batches',
  initialState: { entities: [], loading: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatches.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchBatches.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.entities = action.payload;
      })
      .addCase(fetchBatches.rejected, (state, action) => {
        state.loading = 'idle';
        console.error("Fetch batches failed", action.error);
      });
  },
});

export default batchSlice.reducer;