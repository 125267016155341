import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Button, TextField, Select, MenuItem, InputLabel, FormControl, Card, CardHeader, CardContent, Stack, Snackbar, Alert } from '@mui/material';

import { fetchBatches } from '../redux/slice/batchSlice';
import { saveCustomer, updateCustomer } from '../redux/slice/customerSlice';
import { countryCodes } from '../utils/Constants';
import { utils, validationUtils } from '../utils/utils';

function CustomerSave({ editingCustomer }) {
  const initialClientState = {
    id: null,
    firstName: '',
    lastName: '',
    countryCode: '',
    phoneNumber: '',
    email: '',
    gender: '',
    batchId: ''
  };

  if(editingCustomer) {
    initialClientState.id = editingCustomer.id;
    
    initialClientState.firstName = editingCustomer.firstName;
    initialClientState.lastName = editingCustomer.lastName;

    initialClientState.email = editingCustomer.email;

    const {countryCode, phoneNumber} = utils.extractPhoneNumberComponents(editingCustomer.mobileNumber);
    initialClientState.countryCode = countryCode;
    initialClientState.phoneNumber = phoneNumber;

    initialClientState.gender = "female";
    if(editingCustomer.gender === "M") {
      initialClientState.gender = "male";
    }

    if(editingCustomer.batches.length) {
      initialClientState.batchId = editingCustomer.batches[0].id;
    }
  }

  const [client, setClient] = useState(initialClientState);

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const batches = useSelector((state) => state.batches.entities);
  const batchesLoading = useSelector((state) => state.batches.loading);

  const saveStatus = useSelector((state) => state.customers.loading);
  const saveError = useSelector((state) => state.customers.error);

  useEffect(() => {
    if (batchesLoading === 'idle') {
      dispatch(fetchBatches());
    }
  }, [dispatch]);

  const handleChange = (event) => {
    setClient({
      ...client,
      [event.target.name]: event.target.value
    });

    if (event.target.name === 'email') {
      if (!validationUtils.validateEmail(event.target.value)) {
        setErrors({
          ...errors,
          email: 'Please enter a valid email address',
        });
      } else {
        setErrors({
          ...errors,
          email: null,
        });
      }
    }
  
    if (event.target.name === 'phoneNumber') {
      if (!validationUtils.validatePhoneNumber(event.target.value)) {
        setErrors({
          ...errors,
          phoneNumber: 'Please enter a valid phone number',
        });
      } else {
        setErrors({
          ...errors,
          phoneNumber: null,
        });
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    let finalClient = utils.deepcopy(client);
    let countryCode = client.countryCode.replace("+", "");
    finalClient.mobileNumber = `${countryCode}${client.phoneNumber}`; //This is the property expected by the API

    if(!editingCustomer) {
      dispatch(saveCustomer(finalClient));
    } else {
      dispatch(updateCustomer(finalClient));
    }
    
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const renderAlert = () => {
    let alert = null;
    if(saveStatus === 'loading') {
      alert = <Alert onClose={handleClose} severity="info">Saving...</Alert>;
    } else if(saveStatus === 'idle' && saveError) {
      alert = <Alert onClose={handleClose} severity="error">Save Failed</Alert>
    } else if(saveStatus === 'idle' && !saveError) {
      alert = <Alert onClose={handleClose} severity="success">Save Successful</Alert>;
    }
    return alert;
  }

  return (
    <Card variant="outlined"> 
      <CardHeader
        title={editingCustomer ? 'Edit Customer' : 'Add Customer'}
      />
      <CardContent>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {renderAlert()}
        </Snackbar>
        <form onSubmit={handleSubmit} >
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ marginBottom: 2 }}>
              <TextField
                fullWidth 
                name="firstName"
                label="First Name"
                value={client.firstName}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth 
                name="lastName"
                label="Last Name"
                value={client.lastName}
                onChange={handleChange}
              />
            </Stack>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ marginBottom: 2 }}>
                <FormControl fullWidth>
                    <InputLabel id="country-code-label">Country Code</InputLabel>
                    <Select
                        labelId="country-code-label"
                        name="countryCode"
                        value={client.countryCode}
                        onChange={handleChange}
                        required
                    >
                        {countryCodes.map((country, index) => (
                            <MenuItem key={index} value={country.code}>
                                {`${country.name} (${country.code})`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    name="phoneNumber"
                    label="Phone Number"
                    value={client.phoneNumber}
                    onChange={handleChange}
                    error={errors.phoneNumber ? true : false}
                    helperText={errors.phoneNumber}
                    required
                />
            </Stack>
            <TextField
                fullWidth
                sx={{ marginBottom: 2 }}
                name="email"
                label="Email"
                value={client.email}
                onChange={handleChange}
                error={errors.email ? true : false}
                helperText={errors.email}
            />
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                    labelId="gender-label"
                    name="gender"
                    value={client.gender}
                    onChange={handleChange}
                    required
                >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="batch-label">Batch</InputLabel>
                <Select
                    labelId="batch-label"
                    name="batchId"
                    value={client.batchId}
                    onChange={handleChange}
                    required
                >
                    {batches.map((batch, index) => (
                        <MenuItem key={index} value={batch.id}>{batch.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                Save
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}

export default CustomerSave;
