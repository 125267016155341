import React, { useState } from "react";
import firebase from "firebase/app";
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, TextField, Grid, Paper, Typography, Alert } from '@mui/material';

import { loginSuccess } from '../redux/slice/userSlice';

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const auth = getAuth();
  const dispatch = useDispatch();

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        dispatch(loginSuccess(result.user));
      })
      .catch((error) => {
        setError("Error signing in! Please contact your support team.");
        console.error("Error signing in! Please contact your support team.", error);
      });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const renderError = () => {
    let errorBox = null;

    if(error !== null) {
      errorBox = <Alert severity="error"> {error} </Alert>
    }

    return errorBox;
  }

  return (
    <div>
      
      <Grid container component="main" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <Typography component="h1" variant="h5">
              Present Studio
            </Typography>
            { renderError() }
            <form noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(event) => onChangeHandler(event)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => onChangeHandler(event)}
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password)
              }}
              >
                Sign In
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SignIn;
